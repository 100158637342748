
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer"
import TimelineThumbnails from "@evercam/shared/components/timelinePlayer/TimelineThumbnails"
import { ETimeline, TimelineIntervalChangeTrigger } from "@evercam/ui"
import type {
  TimelineEventsByType,
  TimelineMarker,
  TimelineInterval,
} from "@evercam/ui"
import Vue from "vue"
import type { PropType, StyleValue } from "vue"
import type {
  Project,
  AdminCamera,
  Camera,
  CameraExid,
  TimelineDateInterval,
  TimelinePrecision,
} from "@evercam/shared/types"
import { TLPlayerDefaultPrecisionBreakpoints } from "@evercam/shared/constants/timeline"
import TimelinePlayerCameraSelector from "@evercam/shared/components/timelinePlayer/TimelinePlayerCameraSelector"
import { TimelineColors } from "@evercam/shared/constants/timeline"

export default Vue.extend({
  name: "TimelinePlayerWrapper",
  components: {
    TimelinePlayerCameraSelector,
    TimelineThumbnails,
    ETimeline,
    EvercamPlayer,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    startDate: {
      type: [String],
      default: undefined,
    },
    endDate: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    eventsGroups: {
      type: Object as PropType<TimelineEventsByType>,
      required: true,
    },
    width: {
      type: [Number],
      default: undefined,
    },
    height: {
      type: [Number],
      default: undefined,
    },
    showThumbnails: {
      type: Boolean,
      default: false,
    },
    hideTimeline: {
      type: Boolean,
      default: false,
    },
    selectedTimestamp: {
      type: [String, Date],
      default: undefined,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    markers: {
      type: Array as PropType<TimelineMarker[]>,
      default: () => [],
    },
    focusedTimestamp: {
      type: [String],
      default: undefined,
    },
    cameraItemWidth: {
      type: [Number],
      default: undefined,
    },
    selectedCamera: {
      type: [Object] as PropType<Camera | AdminCamera>,
      default: undefined,
    },
    fitMarkersOnChange: {
      type: Boolean,
      default: false,
    },
    panToFocusedTimestamp: {
      type: Boolean,
      default: true,
    },
    forbiddenIntervals: {
      type: Array as PropType<TimelineInterval[]>,
      default: () => [],
    },
    focusedInterval: {
      type: [Object] as PropType<TimelineInterval | undefined>,
      default: undefined,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    hideCameraSelector: {
      type: Boolean,
      default: false,
    },
    isAnnotationActive: {
      type: Boolean,
      default: false,
    },
    eTimelineProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    withZoomSlider: {
      type: Boolean,
      default: true,
    },
    playerStart: {
      type: [String, Date],
      required: true,
    },
    playerEnd: {
      type: [String, Date],
      required: true,
    },
    precisionBreakpoints: {
      type: Array as PropType<
        { breakpoint: number; precision: TimelinePrecision }[]
      >,
      default: () => TLPlayerDefaultPrecisionBreakpoints,
    },
  },
  data() {
    return {
      timelineTimestamp: this.selectedTimestamp,
      thumbnailsTranslation: 0,
      precision: undefined as TimelinePrecision | undefined,
      timelineStartDate: this.startDate,
      timelineEndDate: this.endDate,
      visibleInterval: {
        from: this.startDate,
        to: this.endDate,
      },
      cameraSelectorTimestamp: "",
      colors: TimelineColors,
    }
  },
  computed: {
    cameras(): Array<Camera | AdminCamera> {
      return this.project.cameras || []
    },
    cappedForbiddenIntervals(): TimelineInterval[] {
      return [
        {
          startDate: -Infinity,
          endDate: this.projectMinDate,
        },
        ...this.forbiddenIntervals,
        {
          startDate: this.projectMaxDate,
          endDate: Infinity,
        },
      ]
    },
    projectMinDate(): string {
      return this.project.startedAt as string
    },
    projectMaxDate(): string {
      return new Date(Date.now() + 3600_000).toISOString()
    },
    containerStyle(): Record<any, any> {
      let style: StyleValue = { height: "100%" }
      if (this.width) {
        style.maxWidth = `${this.width}px`
      }
      if (this.height) {
        style.maxHeight = `${this.height}px`
      }

      return style
    },
    cameraExid(): CameraExid {
      return (this.selectedCamera.exid || this.selectedCamera.id) as CameraExid
    },
  },
  watch: {
    focusedTimestamp(v) {
      this.timelineTimestamp = v
    },
    eventsGroups(groups: TimelineEventsByType) {
      const isLoading = Object.values(groups).reduce((acc, group) => {
        return (group.isLoading as boolean) || acc
      }, false)

      if (isLoading) {
        return
      }

      this.precision = this.calculatePrecision({
        fromDate: this.visibleInterval.from,
        toDate: this.visibleInterval.to,
      })
    },
    timelineTimestamp(t) {
      this.$emit("timestamp-change", t)
    },
    selectedTimestamp(t) {
      this.timelineTimestamp = t
      this.updateCameraSelectorTimestamp(t)
    },
  },
  mounted() {
    this.updateCameraSelectorTimestamp(this.selectedTimestamp)
  },
  methods: {
    onTimelineResized({ contentRect }: ResizeObserverEntry) {
      this.$emit("timeline-resized", { contentRect })
    },
    onVisibleIntervalChange({
      fromDate,
      toDate,
      transform,
      trigger,
    }: {
      fromDate: string
      toDate: string
      transform: Record<string, any>
      trigger: TimelineIntervalChangeTrigger
    }) {
      if (
        fromDate === this.visibleInterval.from &&
        toDate === this.visibleInterval.to
      ) {
        return
      }

      this.visibleInterval = {
        from: fromDate,
        to: toDate,
      }
      this.thumbnailsTranslation = transform.translation
      if (trigger === TimelineIntervalChangeTrigger.zoom) {
        this.$emit("visible-interval-change", { fromDate, toDate })
      }
    },
    onTimelineDateClicked(t: string) {
      this.timelineTimestamp = t
      this.$emit("seek", t, this.selectedTimestamp)
    },
    onMarkerClicked(m: TimelineMarker) {
      this.onTimelineDateClicked(m.timestamp as string)
      this.$emit("marker-clicked", m)
    },
    calculatePrecision({
      fromDate,
      toDate,
    }: TimelineDateInterval): TimelinePrecision {
      const intervalDuration =
        new Date(toDate).getTime() - new Date(fromDate).getTime()

      return this.precisionBreakpoints.find(
        ({ breakpoint }) => intervalDuration > breakpoint
      )?.precision as TimelinePrecision
    },
    updateCameraSelectorTimestamp(timestamp: string) {
      const fiveMinutesAgo = this.$moment(Date.now()).subtract(5, "minutes")
      const newTime = this.$moment.min(this.$moment(timestamp), fiveMinutesAgo)
      if (!this.cameraSelectorTimestamp) {
        this.cameraSelectorTimestamp = newTime.format()

        return
      }
      const currentTime = this.$moment(this.cameraSelectorTimestamp)
      if (Math.abs(currentTime.diff(newTime, "minutes")) > 1) {
        this.cameraSelectorTimestamp = newTime.format()
      }
    },
  },
})
