
import Vue, { PropType } from "vue"
import { TimelineEvent } from "@evercam/ui"
import TimelinePlayerGateReportTooltip from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerGateReportTooltip"
import TimelinePlayerImageTooltip from "@evercam/shared/components/timelinePlayer/TimelinePlayerImageTooltip"
import { camelToKebabCase } from "@evercam/shared/utils"

export default Vue.extend({
  components: {
    TimelinePlayerImageTooltip,
    TimelinePlayerGateReportTooltip,
  },
  props: {
    counts: {
      type: Array as PropType<{ count: number; type: string }[]>,
      default: () => [],
    },
    event: {
      type: Object as PropType<TimelineEvent>,
      default: () => ({}),
    },
    type: {
      type: String as PropType<"gateReport" | "anpr" | "motion" | string>,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    token: {
      type: String,
      default: "",
    },
  },
  computed: {
    anprItem() {
      return {
        label: `Plate: ${this.event.label}`,
        timestamp: `Time: ${this.$moment
          .tz(this.event.timestamp, this.timezone)
          .format("YYYY-MM-DD HH:mm:ss")}`,
        src: this.event.thumbnailUrl,
      }
    },
  },
  methods: {
    camelToKebabCase: camelToKebabCase,
  },
})
