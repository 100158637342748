
import Vue, { PropType } from "vue"
import { Comment } from "@evercam/shared/types"
import CommentTooltip from "@evercam/shared/components/Comments/CommentTooltip"

export default Vue.extend({
  name: "TimelinePlayerCommentTooltip",
  components: {
    CommentTooltip,
  },
  props: {
    item: {
      type: Object as PropType<Comment>,
      default: () => ({} as Comment),
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
  },
})
